import { render } from 'react-dom'
import React, { Suspense } from 'react'
import { Canvas } from 'react-three-fiber'
import { Html } from '@react-three/drei'

import Scene1 from './Scene1'
import "./base.css"

function Loader() {
  return (
    <Html center>
    </Html>
  )
}

function App(props) {
  const title = props
  return (
    <Canvas concurrent shadowMap camera={{ position: [0, 0, 5], fov: 70 }} >
      <color attach="background" args={['#000']} />
      <Suspense fallback={<Loader />}>
        <Scene1 title={title}/>
      </Suspense>
      <ambientLight intensity={0.6} />
    </Canvas>
  )
}

function Body() {
  return (
    <main>
      <div className="content">
        <App title="Dijital Çözümler" />
      </div>
    </main>
  )
}

render(<Body />, document.querySelector('#root'))
